import { IncomingMessage } from 'http'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { getDomainCode } from '../domain'
import { DomainCode } from '../types'

export const getDomainFromRequest = (req: IncomingMessage): DomainCode => {
  const host = req.headers.host
  const domainCode = host && getDomainCode(host)
  return domainCode || 'jp'
}

export const withDomainProp = <T extends Record<string, unknown>>(
  getServerSideProps: GetServerSideProps<T>
): GetServerSideProps<T & { _domain: DomainCode }> => {
  return async (context: GetServerSidePropsContext) => {
    const domainCode = getDomainFromRequest(context.req)
    const result = await getServerSideProps(context)

    if ('notFound' in result || 'redirect' in result) {
      return result
    }
    return {
      ...result,
      props: {
        ...(await result.props),
        _domain: domainCode,
      },
    }
  }
}
