export const Custom500: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center pb-[15px] pt-[120px]">
      <section className="flex flex-col items-center pb-[30px]">
        <h1 className="text-[30px] font-bold">System Error</h1>
        <div className="h-[50px]" />
        <p className="text-center text-[13px] leading-relaxed text-[#515151]">
          大変申し訳ございません。システムエラーが発生しました。
          <br />
          WEARトップページより再度アクセスしてください。
        </p>
        <div className="h-[30px]" />
        <p className="text-center">
          <a
            href="/"
            className="block w-[180px] rounded-[3px] bg-black py-3 font-bold text-white"
          >
            WEAR TOP
          </a>
        </p>
      </section>
    </div>
  )
}
