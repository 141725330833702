import { joinParams } from '~/lib/joinParams'
import { AffiliateObjectPostData, AffiliatePostData } from './types'

const affiliateObjectPath = '/sp/common/json/affiliate_object.json'
const affiliatePath = '/sp/common/json/affiliate.json'

export const postAffiliateObject = (data: AffiliateObjectPostData) => {
  const params = joinParams(data)
  fetch(affiliateObjectPath, { method: 'POST', body: params }).catch(
    () => undefined
  )
}

export const postAffiliate = (data: AffiliatePostData) => {
  const params = new URLSearchParams(
    Object.entries(data).map(([key, val]) => [
      key,
      val === null || val === undefined ? '' : val.toString(),
    ])
  )
  fetch(affiliatePath, { method: 'POST', body: params }).catch(() => undefined)
}
