/* eslint-disable max-statements */
import { startOfTomorrow } from 'date-fns'
import { ParsedUrlQuery } from 'querystring'
import {
  setCookie as _setCookie,
  removeCookie as _removeCookie,
  getCookie as _getCookie,
} from '~/lib/cookieUtil'
import { ScreenIds } from '~/types/screenIds'

type Prefix = '' | 'Af' | 'Pre' | 'AfPre'
type Name = 'Screen' | 'Object'
type AffiliateCookieName = `${Prefix}${Name}ID`

const setCookie = _setCookie<AffiliateCookieName>
const removeCookie = _removeCookie<AffiliateCookieName>
const getCookie = _getCookie<AffiliateCookieName>

const AffiliateObjectSessionToCookiePath =
  '/sp/common/json/affiliate_object_session_to_cookie.json'

const coordinateListPathPattern = new RegExp(
  '^/(|men-|women-|kids-)(coordinate|category(/[^/]+){1,2}|brand(/[^/]+){1,3})/?$'
)

type Context = {
  expires: Date
  isImageSearch: boolean
  query: ParsedUrlQuery
}
const generalPreprocess = () => {
  removeCookie('AfScreenID')
  removeCookie('AfObjectID')
  removeCookie('ScreenID')
  removeCookie('ObjectID')

  removeCookie('AfPreScreenID')
  removeCookie('AfPreObjectID')
  removeCookie('PreScreenID')
  removeCookie('PreObjectID')
}
const coordinateDetailPreprocess = () => {
  removeCookie('AfScreenID')
  removeCookie('AfObjectID')
  removeCookie('ScreenID')
  removeCookie('ObjectID')
}
const coordinateListPostprocess = ({ expires }: Context) => {
  setCookie('AfScreenID', ScreenIds.CoordinateList, expires)
}
const coordinateDetailPostprocess = ({
  expires,
  isImageSearch,
  query,
}: Context) => {
  setCookie('AfScreenID', ScreenIds.CoordinateDetail, expires)
  if (typeof query.coordinateId === 'string')
    setCookie('AfObjectID', query.coordinateId, expires)

  const afPreScreenID = getCookie('AfPreScreenID')
  const afPreObjectID = getCookie('AfPreObjectID')
  if (afPreObjectID) {
    if (afPreScreenID) setCookie('PreScreenID', afPreScreenID, expires)
    setCookie('PreObjectID', afPreObjectID, expires)
    removeCookie('AfPreScreenID')
    removeCookie('AfPreObjectID')
  } else {
    removeCookie('PreObjectID')
    if (!isImageSearch) {
      removeCookie('PreScreenID')
    }
  }
}
const itemDetailPostprocess = ({ expires, query }: Context) => {
  const afScreenID = getCookie('AfScreenID')
  if (afScreenID) setCookie('ScreenID', afScreenID, expires)
  const afObjectID = getCookie('AfObjectID')
  if (afObjectID) setCookie('ObjectID', afObjectID, expires)
  if (afObjectID === query.itemId) {
    setCookie('ObjectID', '0', expires)
  }
  if (
    afScreenID !== ScreenIds.CoordinateDetail &&
    getCookie('PreScreenID') !== ScreenIds.ImageSearch &&
    getCookie('AfScreenID') === ScreenIds.ItemList
  ) {
    removeCookie('AfPreScreenID')
    removeCookie('AfPreObjectID')
    removeCookie('PreScreenID')
    removeCookie('PreObjectID')
  }
  setCookie('AfScreenID', ScreenIds.ItemDetail, expires)
  if (typeof query.itemId === 'string')
    setCookie('AfObjectID', query.itemId, expires)
}
const itemListPostprocess = ({ expires }: Context) => {
  setCookie('AfScreenID', ScreenIds.ItemList, expires)
  setCookie('ObjectID', '0', expires)
}
const coordinateItemDetailPostprocess = ({ expires, query }: Context) => {
  const afScreenID = getCookie('AfScreenID')
  if (afScreenID) setCookie('ScreenID', afScreenID, expires)
  const afObjectID = getCookie('AfObjectID')
  if (afObjectID) setCookie('ObjectID', afObjectID, expires)
  if (afObjectID === query.coordinateItemId) {
    setCookie('ObjectID', '0', expires)
  }
  if (afScreenID !== ScreenIds.CoordinateDetail) {
    removeCookie('AfPreScreenID')
    removeCookie('AfPreObjectID')
    removeCookie('PreScreenID')
    removeCookie('PreObjectID')
  }
  setCookie('AfScreenID', ScreenIds.CoordinateItemDetail, expires)
  if (typeof query.coordinateItemId === 'string')
    setCookie('AfObjectID', query.coordinateItemId, expires)
}
const folderDetailPostprocess = ({ expires, query }: Context) => {
  if (typeof query.folderId === 'string') {
    setCookie('AfPreObjectID', query.folderId, expires)
    setCookie('AfObjectID', query.folderId, expires)
  }
  setCookie('AfPreScreenID', ScreenIds.FolderDetail, expires)
  setCookie('AfScreenID', ScreenIds.FolderDetail, expires)
}
const memberDetailPostprocess = ({ expires }: Context) => {
  setCookie('AfScreenID', ScreenIds.User, expires)
}
const myPagePostprocess = ({ expires }: Context) => {
  setCookie('AfScreenID', ScreenIds.User, expires)
  setCookie('AfObjectID', '0', expires)
}
const imageSearchPostprocess = ({ expires }: Context) => {
  setCookie('PreScreenID', ScreenIds.ImageSearch, expires)
  setCookie('AfScreenID', ScreenIds.ItemList, expires)
  setCookie('AfObjectID', '0', expires)
}
const topPostprocess = ({ expires }: Context) => {
  setCookie('AfScreenID', ScreenIds.Top, expires)
}

export const initAffiliate = (
  pathname: string,
  query: ParsedUrlQuery
): Promise<void> => {
  const isImageSearch =
    getCookie('PreScreenID') === ScreenIds.ImageSearch &&
    getCookie('AfScreenID') === ScreenIds.ItemList
  // クッキーは当日中保持
  const expires = startOfTomorrow()
  const context = {
    expires,
    isImageSearch,
    query,
  } as const satisfies Context

  // アイテム詳細・着用アイテム詳細以外の場合は、残っているセッションを削除
  // アイテム詳細・着用アイテム詳細・コーデ詳細以外の場合は、SaveIDのセッションを削除
  if (
    !pathname.includes('/item/[itemId]') &&
    !pathname.includes('/snapitem/[coordinateItemId]')
  ) {
    if (pathname.includes('/[username]/[coordinateId]')) {
      coordinateDetailPreprocess()
    } else {
      generalPreprocess()
    }
  }

  // 遷移元情報が登録されているSessionの情報をCookieに保持してSessionを削除
  // wear1のjsonにリクエストしてwear1でSessionを扱う
  /* eslint-disable complexity */
  return (
    fetch(AffiliateObjectSessionToCookiePath)
      .then(() => {
        // ページ別の処理
        // TODO: リファクタ AbstractURLを使って判定するとか(?)
        // eslint-disable-next-line no-restricted-syntax
        switch (true) {
          // コーデ一覧
          case pathname.match(coordinateListPathPattern) !== null:
            coordinateListPostprocess(context)
            break
          // コーデ詳細
          case pathname === '/[username]/[coordinateId]':
            coordinateDetailPostprocess(context)
            break
          // アイテム詳細
          case pathname === '/item/[itemId]':
            itemDetailPostprocess(context)
            break
          // アイテム一覧
          case pathname === '/item':
          case pathname === '/men-item':
          case pathname === '/women-item':
          case pathname === '/kids-item':
            itemListPostprocess(context)
            break
          // 着用アイテム詳細
          case pathname === '/snapitem/[coordinateItemId]':
            coordinateItemDetailPostprocess(context)
            break
          // フォルダ詳細
          case pathname === '/[username]/favorite/[folderId]':
            folderDetailPostprocess(context)
            break
          // メンバー詳細
          case pathname === '/[username]':
            memberDetailPostprocess(context)
            break
          // マイページ
          case pathname === '/mypage':
            myPagePostprocess(context)
            break
          // 画像検索
          case pathname === '/news/imagesearch':
            imageSearchPostprocess(context)
            break
          // TOPページ
          case pathname === '/':
            topPostprocess(context)
            break
          default:
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
  )
}
