import {
  NEXT_PUBLIC_WEAR2_AUTH_GLOBAL_URL,
  NEXT_PUBLIC_WEAR2_AUTH_JP_URL,
  NEXT_PUBLIC_WEAR2_AUTH_TW_URL,
  NEXT_PUBLIC_WEAR_WEB_GLOBAL_URL,
  NEXT_PUBLIC_WEAR_WEB_JP_URL,
  NEXT_PUBLIC_WEAR_WEB_TW_URL,
} from '~/lib/env'
import { DomainCode } from './types'

const domainCodeToURLMap: Record<DomainCode, URL> = {
  tw: new URL(NEXT_PUBLIC_WEAR_WEB_TW_URL),
  global: new URL(NEXT_PUBLIC_WEAR_WEB_GLOBAL_URL),
  jp: new URL(NEXT_PUBLIC_WEAR_WEB_JP_URL),
}

const domainCodeToWear2AuthUrlMap: Record<DomainCode, URL> = {
  tw: new URL(NEXT_PUBLIC_WEAR2_AUTH_TW_URL),
  global: new URL(NEXT_PUBLIC_WEAR2_AUTH_GLOBAL_URL),
  jp: new URL(NEXT_PUBLIC_WEAR2_AUTH_JP_URL),
}

const hostnameToDomainMap: Record<string, DomainCode> = Object.fromEntries([
  ...Object.entries(domainCodeToURLMap).map(([code, name]) => [
    name.host,
    code,
  ]),
  ...Object.entries(domainCodeToWear2AuthUrlMap).map(([code, name]) => [
    name.host,
    code,
  ]),
])

export const getUrlByDomainCode = (domainCode: DomainCode) =>
  domainCodeToURLMap[domainCode]

export const getDomainCode = (hostname: string): DomainCode | undefined =>
  hostnameToDomainMap[hostname]

const allowedRedirectHosts = [
  ...Object.values(domainCodeToURLMap).map((url) => url.host),
  ...Object.values(domainCodeToWear2AuthUrlMap).map((url) => url.host),
]

export const isAllowedRedirectHost = (hostname: string): boolean =>
  allowedRedirectHosts.includes(hostname)

export const getWear2AuthUrl = (hostname: string) => {
  const domainCode = getDomainCode(hostname) ?? 'jp'
  return domainCodeToWear2AuthUrlMap[domainCode]
}

export const isJpHost = (hostname: string) =>
  hostname === domainCodeToURLMap.jp.host

export type { DomainCode }
