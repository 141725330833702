import React, { ErrorInfo, ReactNode } from 'react'

type Props = {
  fallbackComponent?: ReactNode
  children: ReactNode
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI
    // eslint-disable-next-line no-console
    console.log('getDerivedStateFromError', error)

    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    // eslint-disable-next-line no-console
    console.log('componentDidCatch', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent
    }

    return this.props.children
  }
}

export default ErrorBoundary
