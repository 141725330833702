import { datadogRum } from '@datadog/browser-rum'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import React from 'react'
import { SWRConfig } from 'swr'
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary'
import { Custom500 } from '~/features/500'
import { AffiliateInitProvider } from '~/features/shared/affiliate'
import { appWithDomainContext } from '~/features/shared/domain/context'
import { useResetAccessToken } from '~/hooks/useResetAccessToken'
import { NEXT_PUBLIC_ENVIRONMENT } from '~/lib/env'
import './style.css'

const GtmTagScript = dynamic(
  () => import('~/features/shared/gtmEvent/components/GtmTag'),
  { ssr: false }
)

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  datadogRum.init({
    applicationId: '5edc0489-7b1e-455a-a27f-115a447eaa9e',
    clientToken: 'pub89df295ac389deda404fffe8fd7669cd',
    site: 'datadoghq.com',
    service: 'wear2-web',
    env: NEXT_PUBLIC_ENVIRONMENT,
    version: process.env.NEXT_PUBLIC_BUILD_ID,
    // staging環境では全てのエラーを送信する
    sessionSampleRate: NEXT_PUBLIC_ENVIRONMENT === 'staging' ? 100 : 1,
    // tureにするとクリック情報が送られ、機密情報が含まれてしまう可能性があるのでfalseにしておくこと
    trackUserInteractions: false,
  })
}

const CustomApp: React.FC<AppProps<SSRConfig & Record<string, unknown>>> = ({
  Component,
  pageProps,
}) => {
  useResetAccessToken()

  return (
    <ErrorBoundary fallbackComponent={<Custom500 />}>
      <SWRConfig
        value={{
          revalidateIfStale: false,
          revalidateOnReconnect: false,
          errorRetryCount: 5,
          fallback: pageProps.fallback ?? {},
        }}
      >
        <AffiliateInitProvider>
          <GtmTagScript />
          <Component {...pageProps} />
        </AffiliateInitProvider>
      </SWRConfig>
    </ErrorBoundary>
  )
}

export default appWithTranslation(appWithDomainContext(CustomApp))
