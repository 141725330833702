import Cookies from 'js-cookie'
import { NEXT_PUBLIC_COOKIE_DOMAIN } from './env'

export const setCookie = <T extends string = string>(
  name: T,
  value: string,
  expires: Date
) => {
  Cookies.set(name, value, {
    expires: expires,
    domain: NEXT_PUBLIC_COOKIE_DOMAIN,
    path: '/',
  })
}

export const removeCookie = <T extends string = string>(name: T) => {
  Cookies.remove(name, {
    domain: NEXT_PUBLIC_COOKIE_DOMAIN,
    path: '/',
  })
}

export const getCookie = <T extends string = string>(name: T) => {
  return Cookies.get(name)
}
