import { ScreenIds } from '~/types/screenIds'

/**
 * ActionTypeの定義
 * 1：遷移、2：Save、3：コメント、4：いいね、5：BUY、
 * 6：クローゼット
 */
export const ActionTypes = {
  Transition: '1',
  Save: '2',
  Comment: '3',
  Like: '4',
  Buy: '5',
  Closet: '6',
  Search: '7',
} as const

export type ActionTypes = (typeof ActionTypes)[keyof typeof ActionTypes]

export type AffiliateObjectPostData = {
  object_id?: number
  screen_id: ScreenIds
}

export type AffiliatePostData = {
  member_id: number | null
  screen_id: ScreenIds
  pre_screen_id?: ScreenIds
  object_id: number | null
  pre_object_id?: number | null
  item_id: number | null
  snap_item_id: number | null
  buy_item_id: number | undefined | null
  action_type: ActionTypes
  // FIXME: Localeの型が効かないので要リファクタリング
  locale_id: string
}
