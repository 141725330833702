/* Auto generated by typedotenv */
export const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as string;
export const NEXT_PUBLIC_USE_MSW = process.env.NEXT_PUBLIC_USE_MSW as string;
export const NEXT_PUBLIC_CONNECT_API_URL = process.env.NEXT_PUBLIC_CONNECT_API_URL as string;
export const NEXT_PUBLIC_WEAR2_WEB_URL = process.env.NEXT_PUBLIC_WEAR2_WEB_URL as string;
export const NEXT_PUBLIC_WEAR_WEB_JP_URL = process.env.NEXT_PUBLIC_WEAR_WEB_JP_URL as string;
export const NEXT_PUBLIC_WEAR_WEB_GLOBAL_URL = process.env.NEXT_PUBLIC_WEAR_WEB_GLOBAL_URL as string;
export const NEXT_PUBLIC_WEAR_WEB_TW_URL = process.env.NEXT_PUBLIC_WEAR_WEB_TW_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_JP_URL = process.env.NEXT_PUBLIC_WEAR2_AUTH_JP_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_GLOBAL_URL = process.env.NEXT_PUBLIC_WEAR2_AUTH_GLOBAL_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_TW_URL = process.env.NEXT_PUBLIC_WEAR2_AUTH_TW_URL as string;
export const NEXT_PUBLIC_WEAR2_AUTH_CLIENT_ID = process.env.NEXT_PUBLIC_WEAR2_AUTH_CLIENT_ID as string;
export const NEXT_PUBLIC_LOGOUT_URL = process.env.NEXT_PUBLIC_LOGOUT_URL as string;
export const NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_URL = process.env.NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_URL as string;
export const NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_API_KEY = process.env.NEXT_PUBLIC_BUSINESS_EVENT_LOGGING_API_KEY as string;
export const NEXT_PUBLIC_ZOZOTOWN_URL = process.env.NEXT_PUBLIC_ZOZOTOWN_URL as string;
export const NEXT_PUBLIC_COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string;
export const NEXT_PUBLIC_AD_SCRIPT = process.env.NEXT_PUBLIC_AD_SCRIPT as string;
export const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string;
export const SEND_SENTRY = process.env.SEND_SENTRY as string;
export const NEXT_PUBLIC_ANDROID_PACKAGE_NAME = process.env.NEXT_PUBLIC_ANDROID_PACKAGE_NAME as string;
export const NEXT_PUBLIC_ANDROID_FINGERPRINTS = process.env.NEXT_PUBLIC_ANDROID_FINGERPRINTS as string;
export const NEXT_PUBLIC_COMMA_SEPARATED_APP_IDS = process.env.NEXT_PUBLIC_COMMA_SEPARATED_APP_IDS as string;
export const NEXT_PUBLIC_COORDINATE_DETAIL_ZOZOAD = process.env.NEXT_PUBLIC_COORDINATE_DETAIL_ZOZOAD as string;
export const NEXT_PUBLIC_COORDINATE_LIST_ZOZOAD = process.env.NEXT_PUBLIC_COORDINATE_LIST_ZOZOAD as string;
export const NEXT_PUBLIC_DELETE_INTERDOMAIN_REDIRECT = process.env.NEXT_PUBLIC_DELETE_INTERDOMAIN_REDIRECT as string;
