import { createContext, useContext, useEffect, useState } from 'react'
import { getDomainCode, getUrlByDomainCode } from '../domain'
import { DomainCode } from '../types'

const DomainContext = createContext<DomainCode>('jp')

const getDomainFromProps = (props: Record<string, unknown>): DomainCode => {
  const domain = props._domain
  switch (domain) {
    case 'jp':
    case 'global':
    case 'tw':
      return domain
    default:
      return 'jp'
  }
}

export const useDomainCode = () => useContext(DomainContext)

export const useBaseUrl = (): URL => getUrlByDomainCode(useDomainCode())

export const appWithDomainContext = <T extends Record<string, unknown>>(
  App: React.FC<T>
): React.FC<T> => {
  const AppWithDomain: React.FC<T> = (props) => {
    const [domainCode, setDomainCode] = useState(getDomainFromProps(props))
    useEffect(() => {
      setDomainCode(getDomainCode(window.location.host) || 'jp')
    }, [])
    return (
      <DomainContext.Provider value={domainCode}>
        <App {...props} />
      </DomainContext.Provider>
    )
  }
  return AppWithDomain
}
