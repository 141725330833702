import { useEffect } from 'react'
import { StorageKeys, useLocalStorage } from './useLocalStorage'

/**
 * 有効期限切れのアクセストークンを削除する
 */
export const useResetAccessToken = () => {
  const { reset: resetAccessToken } = useLocalStorage(StorageKeys.AccessToken)
  const { reset: resetUserName } = useLocalStorage(StorageKeys.UserName)
  const { value: accessTokenExpiration, reset: resetAccessTokenExpiration } =
    useLocalStorage(StorageKeys.AccessTokenExpiration)

  useEffect(() => {
    if (!accessTokenExpiration) return
    const currentTime = new Date().getTime()
    if (Number(accessTokenExpiration) < currentTime) {
      resetAccessToken()
      resetUserName()
      resetAccessTokenExpiration()
    }
  }, [
    accessTokenExpiration,
    resetAccessToken,
    resetAccessTokenExpiration,
    resetUserName,
  ])
}
