/**
 * クエリパラメーターを組み立てる
 * @param params key=valueの形でクエリパラメーターとなる
 * @return URL
 */
export const joinParams = (params: {
  [key in string]: string | string[] | number | number[] | null | undefined
}) => {
  return new URLSearchParams(
    Object.entries(params).flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map((v) => [key, v.toString()])
        : value !== null && value !== undefined
          ? [[key, value.toString()]]
          : []
    )
  )
}
