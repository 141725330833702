/**
 * 各画面のScreenIdの定義
 * 1：ユーザ/マイページ、2：ブランド詳細、3：コーディネート詳細、4：アイテム詳細、5：アイテム一覧、
 * 6：タイムライン、7：直接起動（SP）、8：バーコードスキャン、9：お知らせ、10：スナップアイテム詳細、
 * 11：ショップ詳細、12：TOPページ、13：コーデ一覧、14: フォルダ詳細、 15: 見つける、
 * 16: 動画詳細ways、33: テストで使用しているもの
 * 50: 画像検索（手持ちの画像から探す）
 */
export const ScreenIds = {
  User: '1',
  BrandDetail: '2',
  CoordinateDetail: '3',
  ItemDetail: '4',
  ItemList: '5',
  TimeLine: '6',
  SP: '7',
  BarcodeScan: '8',
  Notifications: '9',
  CoordinateItemDetail: '10',
  ShopDetail: '11',
  Top: '12',
  CoordinateList: '13',
  FolderDetail: '14',
  Search: '15',
  WAYS: '16',
  Test: '33',
  ImageSearch: '50',
} as const

export type ScreenIds = (typeof ScreenIds)[keyof typeof ScreenIds]
