import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { isBrowser } from '../isBrowser'

export const createStorageHook = <
  Keys extends string,
  DefaultTypes extends { [key in Keys]: unknown } = { [key in Keys]: unknown },
>(
  storage: Storage,
  defaultValues: DefaultTypes,
  { ssrCompatible = true }: { ssrCompatible?: boolean } = {}
) => {
  return <Key extends Keys>(
    key: Key,
    fallbackValue = defaultValues[key]
  ): Readonly<{
    value: DefaultTypes[Key]
    set: Dispatch<SetStateAction<DefaultTypes[Key]>>
    reset: () => void
    /** ルーターフィールドがクライアント側で更新され、使用できる状態になっているかどうか。 useEffectメソッド内でのみ使用する必要があり、サーバーでの条件付きレンダリングには使用しないでください。 */
    isReady: boolean
  }> => {
    const parseOrFallback = useCallback(
      (val: string | null): DefaultTypes[Key] => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return val === null ? fallbackValue : JSON.parse(val)
        } catch {
          return fallbackValue
        }
      },
      [fallbackValue]
    )

    const [isReady, setIsReady] = useState(false) // hydration flag
    const [value, setValue] = useState<DefaultTypes[Key]>(() => {
      // SSR互換の場合hydration時に差異が出ないよう初期値はfallbackValueとする
      if (ssrCompatible || !isBrowser) return fallbackValue
      return parseOrFallback(storage.getItem(key))
    })

    const set = useCallback<Dispatch<SetStateAction<DefaultTypes[Key]>>>(
      (update) => {
        setValue((oldValue) => {
          const val = update instanceof Function ? update(oldValue) : update
          try {
            storage.setItem(key, JSON.stringify(val))
          } catch (e) {
            console.warn(e)
          }
          return val
        })
      },
      [key]
    )

    const reset = useCallback(() => {
      storage.removeItem(key)
      setValue(fallbackValue)
    }, [fallbackValue, key])

    useEffect(() => {
      if (!isReady) setIsReady(true)
      setValue(parseOrFallback(storage.getItem(key)))
      if (
        storage !== window.localStorage &&
        storage !== window.sessionStorage
      ) {
        return
      }
      const onStorageChange = (e: StorageEvent) => {
        if (e.storageArea !== storage || e.key !== key) return
        setValue(parseOrFallback(e.newValue))
      }
      window.addEventListener('storage', onStorageChange)
      return () => {
        window.removeEventListener('storage', onStorageChange)
      }
    }, [isReady, key, parseOrFallback])

    return { value, set, reset, isReady }
  }
}
